import React from "react";
import {graphql} from "gatsby";
import { Link } from 'gatsby';
import {Col, Container, Row, Card} from "react-bootstrap";
import BackgroundImage from "gatsby-background-image/index";
import Img from 'gatsby-image';

import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import HeaderCol from '../components/headerCol/headerCol';
import Swirl from '../images/backgrounds/bg-swirl.svg';
import { ReactComponent as GradientArrow } from '../images/icons/icon-arrow.svg';


const LearnMorePage = ({ data }) => {
  // Map ACF group content to variable
  const content = data.wpPage.pageLearnMore;

  return (
    <Layout>
      <Seo
        title={data.wpPage.seo.metaTitle}
        description={data.wpPage.seo.metaDesc}
        image={data.wpPage.seo.opengraphImage?.localFile.publicURL}
        ogTitle={data.wpPage.seo.opengraphTitle}
        ogDescription={data.wpPage.seo.opengraphDescription}
        ogImage={data.wpPage.seo.opengraphImage?.localFile.publicURL}
        twitterTitle={data.wpPage.seo.twitterTitle}
        twitterDescription={data.wpPage.seo.twitterDescription}
        twitterImage={data.wpPage.seo.twitterImage?.localFile.publicURL}
      />

      <BackgroundImage
        Tag="header"
        fluid={content.learnMoreHeader.background.localFile.childImageSharp.fluid}
        fadeIn={false}
        style={{ opacity: `1 !important` }}
      >
        <Container>
          <Row>
            <HeaderCol>
              <h1>{content.learnMoreHeader.headline}</h1>
              <p>{content.learnMoreHeader.subheadline}</p>
            </HeaderCol>
          </Row>
        </Container>

        {/* eslint-disable jsx-a11y/alt-text */}
        <img
          src={Swirl}
          style={{opacity: 1, transform: 'translateY(1px)', height: 'auto', width: '100vw'}}
        />
        {/* eslint-enable jsx-a11y/alt-text */}
      </BackgroundImage>

      <section className='pt-4 pt-md-6'>
        <Container>
          <Row className='text-center'>
            <Col md={6} className={`mb-3 mb-md-0`}>
              <h2>{content.learnMoreSubheader.visionHeadline}</h2>
              <p>{content.learnMoreSubheader.visionDescription}</p>
            </Col>

            <Col md={6}>
              <h2>{content.learnMoreSubheader.missionHeadline}</h2>
              <p>{content.learnMoreSubheader.missionDescription}</p>
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container className='py-4 py-md-6'>
          <Row className="justify-content-center text-center mb-5">
            <HeaderCol>
              <h2>{content.learnMoreValues.headline}</h2>
            </HeaderCol>
          </Row>

          <Row className="justify-content-around mb-6">
            {content.learnMoreValues.values.map((value, index) => (
              <Col md={3} xs={6} key={index} className="text-center">
                <h4>{value.valueHeadline}</h4>
                <p>{value.valueDescription}</p>
              </Col>
            ))}
          </Row>

          <Row className="justify-content-center text-center mb-3">
            <HeaderCol>
              <h2>{content.learnMoreDifference.headline}</h2>
              <p>{content.learnMoreDifference.description}</p>
            </HeaderCol>
          </Row>

          <Row className={`justify-content-center`}>
            <Col md={4} xs={12} className="text-center mb-4">
              <h4 className='mb-3'>{content.learnMoreDifference.when.headline}</h4>

              <Card className='rounded-pill'>
                <Card.Body className="text-center">
                  <Card.Title className="mb-3">{content.learnMoreDifference.when.subitemOneHeadline}</Card.Title>
                  <Card.Text className="mb-3">
                    {content.learnMoreDifference.when.subitemOneSubheadline}
                  </Card.Text>
                </Card.Body>
              </Card>

              <GradientArrow className='my-3' style={{ transform: 'rotate(90deg)' }}/>

              <Card className='rounded-pill bg-gradient-success'>
                <Card.Body className="text-center">
                  <Card.Title className="mb-3">{content.learnMoreDifference.when.subitemTwoHeadline}</Card.Title>
                  <Card.Text className="mb-3">
                    {content.learnMoreDifference.when.subitemTwoSubheadline}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} xs={12} className="text-center mb-4">
              <h4 className='mb-3'>{content.learnMoreDifference.how.headline}</h4>

              <Card className='rounded-pill'>
                <Card.Body className="text-center">
                  <Card.Title className="mb-3">{content.learnMoreDifference.how.subitemOneHeadline}</Card.Title>
                  <Card.Text className="mb-3">
                    {content.learnMoreDifference.how.subitemOneSubheadline}
                  </Card.Text>
                </Card.Body>
              </Card>

              <GradientArrow className='my-3' style={{ transform: 'rotate(90deg)' }}/>

              <Card className='rounded-pill bg-gradient-success'>
                <Card.Body className="text-center">
                  <Card.Title className="mb-3">{content.learnMoreDifference.how.subitemTwoHeadline}</Card.Title>
                  <Card.Text className="mb-3">
                    {content.learnMoreDifference.how.subitemTwoSubheadline}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4} xs={12} className="text-center mb-4">
              <h4 className='mb-3'>{content.learnMoreDifference.why.headline}</h4>

              <Card className='rounded-pill'>
                <Card.Body className="text-center">
                  <Card.Title className="mb-3">{content.learnMoreDifference.why.subitemOneHeadline}</Card.Title>
                  <Card.Text className="mb-3">
                    {content.learnMoreDifference.why.subitemOneSubheadline}
                  </Card.Text>
                </Card.Body>
              </Card>

              <GradientArrow className='my-3' style={{ transform: 'rotate(90deg)' }}/>

              <Card className='rounded-pill bg-gradient-success'>
                <Card.Body className="text-center">
                  <Card.Title className="mb-3">{content.learnMoreDifference.why.subitemTwoHeadline}</Card.Title>
                  <Card.Text className="mb-3">
                    {content.learnMoreDifference.why.subitemTwoSubheadline}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <div className="banner-primary py-5" style={{ borderBottomRightRadius: '80.9rem', borderTopRightRadius: '80.9rem'}}>
          <Container>
            <Row className="align-items-center">
              <Col md={8}>
                <h4>{content.learnMoreBanner.headline}</h4>
              </Col>

              <Col md={4} className='text-center'>
                <Link to="/get-started/" className='btn btn-gradient d-inline-flex'>
                  {content.learnMoreBanner.button}
                </Link>
              </Col>
            </Row>
          </Container>
        </div>

        <Container className='py-4 py-md-6' id="the-team">
          <Row className="mb-3">
            <Col>
              <h2>{content.learnMoreTeam.headline}</h2>
            </Col>
          </Row>

          <Row className='mb-4'>
            {content.learnMoreTeam.team.map((member, index) => (
              <Col md={6} xs={12} key={`member-${index}`} className='mb-4'>
                <Row className='mb-3 align-items-center'>
                  <Col md={4} xs={6}>
                    <Img fluid={member.headshot.localFile.childImageSharp.fluid} alt={member.name} className='rounded-circle' />
                  </Col>
                  <Col md={8} xs={6}>
                    <h4>{member.name}<br/>{member.pronouns}<br/>{member.title}</h4>
                  </Col>
                </Row>

                <p>{member.bio}</p>
              </Col>
            ))}
          </Row>

          <Row className="mb-3" id="contributors">
            <Col>
              <h2>{content.learnMoreContributors.headline}</h2>
              <p>{content.learnMoreContributors.description}</p>
            </Col>
          </Row>

          <Row>
            {content.learnMoreContributors.contributors.map((contributor, index) => (
              <Col md={4} xs={6} key={`contributor-${index}`}>
                {contributor.name}
              </Col>
            ))}
          </Row>
        </Container>

        <div className="banner-secondary py-5">
          <Container>
            <Row className="align-items-center">
              <Col md={8}>
                <h4>{content.learnMoreBannerTwo.headline}</h4>
              </Col>

              <Col md={4} className='text-center'>
                <Link to="/connect/" className='btn btn-gradient d-inline-flex'>
                  {content.learnMoreBannerTwo.button}
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    wpPage(databaseId: { eq: 330 }) {
        seo {
            metaDesc
            metaTitle
            twitterDescription
            twitterTitle
            twitterImage {
                localFile {
                    publicURL
                }
            }
            opengraphTitle
            opengraphDescription
            opengraphImage {
                localFile {
                    publicURL
                }
            }
        }
      pageLearnMore {
        learnMoreHeader {
          headline
          subheadline
          background {
            localFile {
                ...imageFluidExtraLarge
            }
          }
        }
        learnMoreSubheader {
          visionHeadline
          visionDescription
          missionHeadline
          missionDescription
        }
        learnMoreValues {
          headline
          values {
            valueHeadline
            valueDescription
          }
        }
        learnMoreDifference {
          headline
          description
          when {
            headline
            subitemOneHeadline
            subitemOneSubheadline
            subitemTwoHeadline
            subitemTwoSubheadline
          }
          how {
            headline
            subitemOneHeadline
            subitemOneSubheadline
            subitemTwoHeadline
            subitemTwoSubheadline
          }
          why {
            headline
            subitemOneHeadline
            subitemOneSubheadline
            subitemTwoHeadline
            subitemTwoSubheadline
          }
        }
        learnMoreBanner {
          headline
          button
        }
        learnMoreTeam {
          headline
          team {
            name
            pronouns
            title
            bio
            headshot {
              localFile {
                ...imageFluidSmall
              }
            }
          }
        }
        learnMoreContributors {
          headline
          description
          contributors {
            name
          }
        }
        learnMoreBannerTwo {
          headline
          button
        }
      }
    }
  }
`;

export default LearnMorePage;
